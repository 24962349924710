import TomSelect from 'tom-select';

(function () {
    'use strict';

    // Tom Select
    $('.tom-select').each(function () {
        let options = {
            plugins: {
                checkbox_options: {},
                // clear_button:{
                //     'title':'Remove all selected options',
                // }
            },
        };

        if ($(this).attr('no-checkbox') !== undefined) {
            // remove plugin checkbox_options
            delete options.plugins.checkbox_options;
        }

        if ($(this).attr('use-search') !== undefined) {
            // add plugin dropdown_input
            options = {
                ...options,
                plugins: {
                    ...options.plugins,
                    dropdown_input: {},
                },
            };
        }

        if ($(this).data('placeholder')) {
            options.placeholder = $(this).data('placeholder');
        }

        if ($(this).attr('multiple') !== undefined) {
            options = {
                ...options,
                plugins: {
                    ...options.plugins,
                    remove_button: {
                        title: 'Remove',
                    },
                },
                persist: false,
                // create: true,
                // onDelete: function (values) {
                //     return confirm(
                //         values.length > 1
                //             ? "Are you sure you want to remove these " +
                //                   values.length +
                //                   " items?"
                //             : 'Are you sure you want to remove "' +
                //                   values[0] +
                //                   '"?'
                //     );
                // },
            };
        }

        if ($(this).data('header')) {
            options = {
                ...options,
                plugins: {
                    ...options.plugins,
                    dropdown_header: {
                        title: $(this).data('header'),
                    },
                },
            };
        }

        if ($(this).attr('use-src-image') !== undefined) {
            options = {
                ...options,
                render: {
                    option: function (data, escape) {
                        return `<div class="flex flex-row"><img class="mr-2 w-5 h-5" src="${data.src}"><span>${escape(data.value)}</span></div>`;
                    },
                    item: function (data, escape) {
                        return `<div class="flex flex-row"><img class="mr-2 w-5 h-5" src="${data.src}"><span>${escape(data.value)}</div>`;
                    },
                },
            };
        }

        new TomSelect(this, options);
    });
})();
