import Toastify from 'toastify-js';

(function (cash) {
    'use strict';

    // Copy original code
    cash('body').on('click', '.copy-code', function () {
        let elementId = cash(this).data('target');
        cash(elementId).find('textarea')[0].select();
        cash(elementId).find('textarea')[0].setSelectionRange(0, 99999);
        document.execCommand('copy');

        // push content to an other area using push-to
        let textarea_val = cash(elementId).find('textarea')[0].value;
        let elem_push_to = cash(this).data('push-to');
        if (elem_push_to) {
            cash(elem_push_to).val(textarea_val);
            // we trigger the event input to update the textarea counter or other input checks
            cash(elem_push_to).trigger('input');
        }

        Toastify({
            text: 'Copied!',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true,
            className: 'toastify-content',
        }).showToast();
    });
})(cash);
