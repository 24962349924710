import tippy, { roundArrow, followCursor } from 'tippy.js';

function add_tooltip(element) {
    //
    // Add a tooltip to an element
    //

    let options = {
        content: $(element).attr('title'),
    };

    if ($(element).data('trigger') !== undefined) {
        options.trigger = $(element).data('trigger');
    }

    if ($(element).data('theme') !== undefined) {
        options.theme = $(element).data('theme');
    }

    if ($(element).data('tooltip-content') !== undefined) {
        options.content = $($(element).data('tooltip-content'))[0];
    }

    if ($(element).data('tooltip-followcursor') !== 'true') {
        options.followCursor = $(element).data('tooltip-followcursor');
    }

    if ($(element).data('tooltip-interactive') !== 'true') {
        options.interactive = $(element).data('tooltip-interactive');
    }

    $(element).removeAttr('title');

    tippy(element, {
        arrow: roundArrow,
        animation: 'shift-away',
        allowHTML: true,
        ...options,
        plugins: [followCursor],
    });
}

function add_tooltip_ajax(element) {
    //
    // Add a tooltip to an element which loads content from an API
    //

    let options = {
        content: $(element).attr('title'),
    };

    if ($(element).data('trigger') !== undefined) {
        options.trigger = $(element).data('trigger');
    }

    if ($(element).data('placement') !== undefined) {
        options.placement = $(element).data('placement');
    }

    if ($(element).data('theme') !== undefined) {
        options.theme = $(element).data('theme');
    }

    if ($(element).data('ajax-tooltip-content') !== undefined) {
        options.content = $($(element).data('ajax-tooltip-content'))[0];
    }
    let tooltip_api_url = $(element).data('tooltip-api-url');

    $(element).removeAttr('title');

    tippy(element, {
        arrow: roundArrow,
        animation: 'shift-away',

        onCreate(instance) {
            // Setup our own custom state properties
            instance._isFetching = false;
            instance._src = null;
            instance._error = null;
            instance.setContent('Loading...');
        },

        onShow(instance) {
            if (instance._isFetching || instance._error) {
                return;
            }
            instance._isFetching = true;
            fetch(tooltip_api_url)
                .then((response) => response.text())
                .then((data) => {
                    instance._src = data;
                    instance.setContent(data);
                })
                .catch((error) => {
                    // Fallback if the network request failed
                    instance._error = error;
                    instance.setContent(`Request failed. ${error}`);
                })
                .finally(() => {
                    instance._isFetching = false;
                });
        },
        onHidden(instance) {
            instance.setContent('Loading...');
            // Unset these properties so new network requests can be initiated
            instance._src = null;
            instance._error = null;
        },

        // interactive: true,
        ...options,
    });
}

(function ($) {
    'use strict';

    $('.tooltip').each(function () {
        add_tooltip(this);
    });

    // call add_tooltip_ajax function
    $('.ajax-tooltip').each(function () {
        add_tooltip_ajax(this);
    });
})(jQuery);
