/*
 |--------------------------------------------------------------------------
 | Midone Built-in Components
 |--------------------------------------------------------------------------
 |
 | Import Midone built-in components.
 |
 */
import './bootstrap';
import '@left4code/tw-starter/dist/js/svg-loader';
import '@left4code/tw-starter/dist/js/accordion';
// import "@left4code/tw-starter/dist/js/alert";
import './overwritte_alert';
import '@left4code/tw-starter/dist/js/dropdown';
import '@left4code/tw-starter/dist/js/modal';
import '@left4code/tw-starter/dist/js/tab';
import './overwritte_tab';

// import "./chart";
// import "./highlight";
import './lucide';
import './tippy';
import './datepicker';
import './tom-select';
// import "./tail-select";
// import "./slimselect";
import './validation';
import './notification';

// import "./highlight";
// import "./dropzone";
// import "./tiny-slider";
// import "./zoom";
// import "./tabulator";
// import "./calendar";

/*
 |--------------------------------------------------------------------------
 | Components
 |--------------------------------------------------------------------------
 |
 | Import JS components.
 |
 */
// import "./maps";
// import "./chat";
import './show-modal';
import './show-slide-over';
import './show-dropdown';
import './toggle-filters';
import './search';
import './copy-code';
import './show-code';
import './side-menu';
import './mobile-menu';
import './side-menu-tooltip';
import './dark-mode-switcher';
import './data-import';
import './mms-control';
